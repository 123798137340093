
import { Options, Vue } from "vue-class-component";

@Options({
  name: "CustomBtn",
  props: {
    config: Object,
  },
  data() {
    return {
      popup: false,
      color: '#666666',
    };
  },
  methods: {
    mouseEnter() {
      this.popup = true;
      this.color = '#f19000';
    },
    mouseLeave() {
      this.popup = false;
      this.color = '#666666';
    },
  },
  mounted() {
    console.log(this.$props);
    console.log(this.$props.selectedIcon);
  },
})
export default class CustomBtn extends Vue {}
